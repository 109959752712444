<template>
  <div>
    <locations />
    <div class="page">
      <account-tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <b-form @submit="onSubmit">
            <div class="row g-0 table-type header-col mt-5">
              <template v-if="is_initialize == '0'">
                <div class="col-sm-4">
                  <span class="required">{{ $t("title.common.id") }}</span>
                </div>
                <div class="col-sm-8">
                  {{ form.id }}
                </div>
              </template>

              <template v-if="is_initialize == '1'">
                <div class="col-sm-4">
                  <span class="required">{{ $t("title.common.id") }}</span>
                </div>
                <div class="col-sm-8">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="
                        $t('text.common.field_enter', {
                          field: this.$i18n.t('title.common.id'),
                        })
                      "
                      v-model="form.id"
                      required
                    />
                    <button
                      class="btn btn-primary btn-md icon-check"
                      type="button"
                      @click="availability_check2(1, form.id)"
                    >
                      {{ $t("button.common.availability_check") }}
                    </button>
                  </div>
                  <p class="mt-2 text-subscript">
                    {{ $t("text.main.sign_up1") }}
                  </p>
                </div>
              </template>

              <div class="col-sm-4">
                {{ $t("title.common.handi_score") }}
              </div>
              <div class="col-sm-8">
                <span @click="$refs['modal-handicap-reg'].show()" class="more">
                  {{ form.handicap_index }}
                </span>
              </div>

              <template v-if="is_initialize == '0'">
                <div class="col-sm-4">
                  <span class="required">{{
                    $t("title.membership.current_password")
                  }}</span>
                </div>
                <div class="col-sm-8 d-flex flex-column flex-sm-row">
                  <input
                    v-if="check_form.passwordchk"
                    type="password"
                    class="flex-grow-1"
                    :placeholder="$t('text.membership.enter_current_password')"
                    name="curpassword"
                    v-model="form.curpassword"
                    required
                  />
                  <input
                    v-else
                    type="password"
                    class="flex-grow-1"
                    placeholder="********"
                    disabled
                  />
                  <div
                    class="form-check d-flex align-items-center ms-0 ms-sm-2 mt-2 mt-sm-0"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheck1"
                      v-model="check_form.passwordchk"
                    />
                    <label
                      class="form-check-label text-nowrap"
                      for="flexCheck1"
                    >
                      {{ $t("text.membership.change_password") }}
                    </label>
                  </div>
                </div>
              </template>

              <template v-if="check_form.passwordchk === true">
                <div class="col-sm-4">
                  <span class="required">{{
                    $t("title.membership.new_password")
                  }}</span>
                </div>
                <div class="col-sm-8">
                  <input
                    type="password"
                    :placeholder="$t('text.membership.enter_new_password')"
                    name="newpassword"
                    v-model="form.newpassword"
                  />
                  <!-- <small>{{ formValidation.password }} </small> -->
                  <p class="mt-2 text-subscript">
                    {{ $t("text.main.sign_up2") }}
                  </p>
                </div>
              </template>

              <template v-if="check_form.passwordchk === true">
                <div class="col-sm-4">
                  <span class="required">{{
                    $t("title.membership.confirm_new_password")
                  }}</span>
                </div>
                <div class="col-sm-8">
                  <input
                    type="password"
                    :placeholder="
                      $t('text.membership.enter_confirm_new_password')
                    "
                    name="newpassword_re"
                    v-model="form.newpassword_re"
                  />
                </div>
              </template>

              <div class="col-sm-4">
                <span class="required">{{ $t("title.common.nickname") }}</span>
              </div>
              <div class="col-sm-8">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('text.main.sign_up4')"
                    name="nickname"
                    v-model="form.nickname"
                    required
                  />
                  <button
                    class="btn btn-primary btn-md icon-check"
                    type="button"
                    id="nickname"
                    @click="availability_check2(2, form.nickname)"
                  >
                    {{ $t("button.common.availability_check") }}
                  </button>
                </div>
              </div>

              <div class="col-sm-4">
                <span class="required">{{ $t("title.common.gender") }}</span>
              </div>
              <div class="col-sm-8">
                <div class="form-check d-inline-block me-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="M"
                    id="Male"
                    name="gender"
                    v-model="form.gender"
                    :checked="form.gender === 'M'"
                  />
                  <label class="form-check-label" for="Male">{{
                    $t("title.common.male")
                  }}</label>
                </div>
                <div class="form-check d-inline-block">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="F"
                    id="Female"
                    name="gender"
                    v-model="form.gender"
                    :checked="form.gender === 'F'"
                  />
                  <label class="form-check-label" for="Female">{{
                    $t("title.common.female")
                  }}</label>
                </div>
              </div>

              <div class="col-sm-4">
                <span class="required">{{ $t("title.common.email") }}</span>
              </div>
              <div class="col-sm-8 d-flex flex-column flex-sm-row">
                <input type="text" name="email" v-model="form.email" readonly />
                <div
                  class="form-check d-flex align-items-center ms-0 ms-sm-2 mt-2 mt-sm-0"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheck2"
                    name="emailchk"
                    v-model="check_form.emailchk"
                    @click="availability_reset(3)"
                  />
                  <label
                    class="form-check-label text-nowrap"
                    for="flexCheck2"
                    >{{ $t("title.membership.email_change") }}</label
                  >
                </div>
              </div>

              <template v-if="check_form.emailchk === true">
                <div class="col-sm-4">
                  <span class="required">{{
                    $t("title.membership.new_email")
                  }}</span>
                </div>
                <div class="col-sm-8">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('text.support.inquiry_sub1')"
                      name="newemail"
                      id="newemail"
                      v-model="form.newemail"
                    />
                    <button
                      class="btn btn-primary btn-md icon-check"
                      type="button"
                      @click="availability_check2(3, form.newemail)"
                    >
                      {{ $t("button.common.availability_check") }}
                    </button>
                  </div>
                </div>
              </template>
            </div>
            <!-- // Required -->
            <div class="my-5 border">
              <h6
                class="sub-title collapse-content mb-0 py-3"
                type="button"
                v-b-toggle.collapse-opt-info
              >
                {{ $t("text.common.optional") }}
              </h6>
              <b-collapse id="collapse-opt-info">
                <div class="row g-0 table-type header-col mx-3 mb-3">
                  <div class="col-sm-4">
                    <span>{{ $t("title.membership.profile_image") }}</span>
                  </div>
                  <div class="col-sm-8 d-flex flex-column flex-sm-row">
                    <div class="flex-shrink-0 me-0 me-sm-2 mb-2 mb-sm-0">
                      <img
                        :src="form._filename | get_img"
                        class="center-cropped"
                      />
                      <span class="ms-2 fs-13">{{
                        $t("title.membership.current_image")
                      }}</span>
                    </div>

                    <div class="flex-grow-1">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          readonly
                          :value="targetFileName"
                        />
                        <button
                          type="button"
                          class="btn btn-primary btn-md icon-photo"
                          @click="$refs.file.click()"
                        >
                          {{ $t("button.common.change_image") }}
                          <input
                            type="file"
                            ref="file"
                            @change="handleFileChange"
                            class="d-none"
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <span class="">{{
                      $t("title.membership.first_name")
                    }}</span>
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :placeholder="$t('text.main.sign_up5')"
                      name="firstname"
                      id="firstname"
                      v-model="form.firstname"
                    />
                  </div>

                  <div class="col-sm-4">
                    <span class="">{{ $t("title.membership.last_name") }}</span>
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :placeholder="$t('text.main.sign_up6')"
                      name="lastname"
                      id="lastname"
                      v-model="form.lastname"
                    />
                  </div>

                  <div class="col-sm-4">
                    <span class="">{{ $t("title.support.country") }}</span>
                  </div>

                  <div class="col-sm-8">
                    <div class="d-flex flex-column flex-sm-row">
                      <div class="flex-grow-1">
                        <select
                          name="countryno"
                          v-model="form.countryno"
                          @change="change_country"
                        >
                          <option value="0">
                            {{ $t("title.common.choose") }}
                          </option>
                          <option
                            v-for="(val, index) in country_list"
                            :value="val.countryno"
                            :key="index + 't'"
                          >
                            {{ val.countryname }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <span class="">{{ $t("title.membership.state") }}</span>
                  </div>

                  <div class="col-sm-8">
                    <div class="d-flex flex-column flex-sm-row">
                      <div class="flex-grow-1">
                        <select
                          name="statesno"
                          v-model="form.statesno"
                          @change="change_states"
                        >
                          <option value="">
                            {{ $t("title.common.choose") }}
                          </option>
                          <option
                            v-for="(val, index) in states_list"
                            :value="val.states_pk"
                            :key="index + 't'"
                          >
                            {{ val.statesdesc }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <span class="">{{ $t("title.membership.time_zone") }}</span>
                  </div>

                  <div class="col-sm-8">
                    <div class="d-flex flex-column flex-sm-row">
                      <div class="flex-grow-1">
                        <select
                          name="timezone"
                          id="timezone"
                          v-model="form.timezone"
                        >
                          <option value="0">
                            {{ $t("title.common.choose") }}
                          </option>
                          <option
                            v-for="(val, index) in timezone_list"
                            :value="val.timezonelist_pk"
                            :key="index + 't'"
                          >
                            {{ val.timezonelist_name }} (UTC
                            {{
                              val.timezonelist_offset >= 0
                                ? "+" + val.timezonelist_offset
                                : val.timezonelist_offset
                            }}:00)
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <span class="required">{{
                      $t("title.membership.handedness")
                    }}</span>
                  </div>
                  <div class="col-sm-8">
                    <div class="form-check d-inline-block me-3">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="R"
                        id="Right"
                        name="hand"
                        v-model="form.hand"
                        :checked="form.hand === 'R'"
                      />
                      <label class="form-check-label" for="Right">{{
                        $t("title.membership.right_hand")
                      }}</label>
                    </div>
                    <div class="form-check d-inline-block">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="L"
                        id="Left"
                        name="hand"
                        v-model="form.hand"
                        :checked="form.hand === 'L'"
                      />
                      <label class="form-check-label" for="Left">{{
                        $t("title.membership.left_hand")
                      }}</label>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <span class="required">{{
                      $t("title.membership.tee_height")
                    }}</span>
                  </div>
                  <div class="col-sm-8">
                    <select
                      name="teeheight"
                      id="teeheight"
                      v-model="form.teeheight"
                    >
                      <option
                        v-for="(height, index) in teeheight_arr"
                        :selected="form.teeheight === height"
                        :key="index"
                        :value="height"
                      >
                        {{ height }}
                      </option>
                    </select>
                  </div>

                  <div class="col-sm-4">
                    <span class="required">{{
                      $t("title.tournament.setting")
                    }}</span>
                  </div>
                  <div class="col-sm-8">
                    <div class="row g-2">
                      <div class="col-sm-4">
                        <select
                          name="fieldunit"
                          id="fieldunit"
                          v-model="form.fieldunit"
                        >
                          <option value="0" disabled>
                            {{ $t("filter.setting.fairway") }}
                          </option>
                          <option value="1" :selected="form.fieldunit === '1'">
                            {{ $t("filter.setting.meter") }}
                          </option>
                          <option value="2" :selected="form.fieldunit === '2'">
                            {{ $t("filter.setting.yard") }}
                          </option>
                          <option value="3" :selected="form.fieldunit === '3'">
                            {{ $t("filter.setting.feet") }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <select
                          name="greenunit"
                          id="greenunit"
                          v-model="form.greenunit"
                        >
                          <option value="0" disabled>
                            {{ $t("filter.setting.green") }}
                          </option>
                          <option value="1" :selected="form.greenunit === '1'">
                            {{ $t("filter.setting.meter") }}
                          </option>
                          <option value="2" :selected="form.greenunit === '2'">
                            {{ $t("filter.setting.yard") }}
                          </option>
                          <option value="3" :selected="form.greenunit === '3'">
                            {{ $t("filter.setting.feet") }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <select
                          name="speedunit"
                          id="speedunit"
                          v-model="form.speedunit"
                        >
                          <option value="0" disabled>
                            {{ $t("filter.setting.speed") }}
                          </option>
                          <option value="1" :selected="form.speedunit === '1'">
                            {{ $t("filter.setting.km_h") }}
                          </option>
                          <option value="2" :selected="form.speedunit === '2'">
                            {{ $t("filter.setting.mile_h") }}
                          </option>
                          <option value="3" :selected="form.speedunit === '3'">
                            {{ $t("filter.setting.m_s") }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <div class="agree-box">
              <div class="form-check">
                <input
                  type="checkbox"
                  id="emailagree"
                  class="form-check-input custom-checkbox"
                  v-model="check_form.emailagree"
                  :checked="check_form.emailagree"
                />
                <label class="form-check-label" for="emailagree">
                  {{ $t("text.membership.agree") }}
                </label>
              </div>

              <!-- <div class="justify-content-center justify-content-sm-start mt-4">
                <vue-recaptcha
                  :sitekey="RECAPTCHA_SITEKEY"
                  :loadRecaptchaScript="true"
                  @verify="verify"
                ></vue-recaptcha>
              </div> -->
            </div>

            <div class="btn-bottom-wrapper">
              <b-button
                type="submit"
                size="xl"
                variant="primary"
                aria-label="submit"
                >{{ $t("button.common.save") }}</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>

    <!-- <b-modal
      id="pointListModal"
      ref="pointListModal"
      size="lg"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3>벅스 내역</h3>
        <button
          type="button"
          class="btn-close"
          @click="$refs['pointListModal'].hide()"
        >
          <i class="material-icons">close</i>
        </button>
      </template>
      <p class="modal-subtitle">
        벅스 합계 <span class="point">{{ pointSum | comma }}B</span>
      </p>
      <table class="table board" id="dataTable" width="100%" cellspacing="0">
        <thead>
          <tr>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.number") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">닉네임</th>
            <th class="col-auto d-lg-table-cell">벅스</th>
            <th class="col-auto d-lg-table-cell">비고</th>
            <th class="col-auto d-none d-lg-table-cell">벅스 생성일</th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="pointListCnt">
          <template v-for="(item, index) in pointList">
            <tr :key="index">
              <td class="col-auto d-none d-lg-table-cell">
                {{ pointList.length - index }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.nickname }}
              </td>
              <td class="col-auto d-lg-table-cell point">
                {{ item.points | comma }}
              </td>
              <td class="col-auto d-lg-table-cell">
                {{ item.description || item.title }}
              </td>
              <td class="col-auto d-none d-lg-table-cell text-date">
                {{ item.regdate | GMTtuUTC }}
              </td>

              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="5">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-4">No.</div>
                    <div class="col-8">{{ item.no }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">닉네임</div>
                    <div class="col-8">{{ item.nickname }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">벅스 생성일</div>
                    <div class="col-8">
                      {{ item.regdate | GMTtuUTC }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          aria-label=""
          @click="$bvModal.hide('pointListModal')"
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template>
    </b-modal> -->
    <b-modal
      id="modal-handicap-reg"
      ref="modal-handicap-reg"
      size="xl"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header>
        <h3 class="montserrat">
          {{ $t("page.membership.x_handicap") }}
        </h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          :aria-label="$t('button.common.close')"
          @click="$refs['modal-handicap-reg'].hide()"
        ></button>
      </template>
      <h6 class="sub-title">{{ profile.nickname }}'s Handicap</h6>
      <div>
        <b-table-simple responsive class="scoreboard mb-4">
          <b-tbody v-if="handiscorecnt > 0">
            <b-tr>
              <!-- <b-th rowspan="4">{{ $t("title.common.player") }}</b-th> -->
              <b-th sticky-column>{{ $t("title.common.date") }}</b-th>
              <template v-for="(val, idx) in handiscore">
                <b-td
                  :key="idx"
                  :colspan="val.length"
                  class="text-date text-nowrap"
                  >{{
                    val[Object.keys(val)[0]].regdate | dateformat("YYYY-MM-DD")
                  }}</b-td
                >
              </template>
            </b-tr>

            <b-tr>
              <b-th sticky-column>{{ $t("title.common.play_format") }}</b-th>
              <template v-for="(val, idx) in handiscore">
                <b-td :key="idx" :colspan="val.length"
                  >{{ val[Object.keys(val)[0]].holecount }}H</b-td
                >
              </template>
            </b-tr>

            <b-tr>
              <b-th sticky-column>{{ $t("text.common.subcourse") }}</b-th>
              <template v-for="(val, idx) in handiscore">
                <template v-for="(val_, idx_) in val">
                  <b-td
                    v-if="
                      handiscore.length == idx + 1 && val.length == idx_ + 1
                    "
                    :key="idx + idx_"
                    v-b-popover.hover.left="val_.title"
                    class="text-nowrap"
                  >
                    {{ val_.description }}
                  </b-td>

                  <b-td
                    v-else
                    :key="idx + idx_"
                    v-b-popover.hover.top="val_.title"
                    class="text-nowrap"
                  >
                    {{ val_.description }}
                  </b-td>
                </template>
              </template>
            </b-tr>

            <b-tr>
              <b-th sticky-column>{{ $t("page.membership.my_rounds") }}</b-th>
              <template v-for="(val, idx) in Array(handiscorecnt)">
                <b-td :key="idx"
                  >{{
                    idx == 0
                      ? "Oldest"
                      : idx + 1 == handiscorecnt
                      ? "Newest"
                      : idx + 1
                  }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <!-- <b-th>{{ profile.nickname }}</b-th> -->
              <b-th sticky-column>{{ $t("title.common.score") }}</b-th>
              <template v-for="(val, idx) in handiscore">
                <template v-for="(val_, idx_) in val">
                  <b-td :key="idx + idx_">
                    <span
                      :class="
                        handiscore_limit.find(
                          (v) => v.score_pk == val_.score_pk
                        )
                          ? 'rounded-circle'
                          : ''
                      "
                      >{{ val_.score - val_.base_par }}
                    </span>
                  </b-td>
                </template>
              </template>
            </b-tr>
          </b-tbody>
          <b-tbody v-else>
            <tr>
              <td class="text-center">
                {{ $t("text.common.no_data") }}
              </td>
            </tr>
          </b-tbody>
        </b-table-simple>

        <div class="row g-3">
          <div class="col-sm-12 col-lg-6">
            <p class="mb-2 fw-500 fs-14 text-black">
              {{ $t("title.common.automation") }}
              {{ $t("title.common.handicap") }}
            </p>
            <input
              type="text"
              v-model="form.handi_9"
              readonly
              class="form-control"
            />
          </div>
          <div class="col-sm-12 col-lg-6">
            <p class="mb-2 fw-500 fs-14 text-black">
              {{ $t("title.common.activation") }}
              {{ $t("title.common.handicap") }}
            </p>
            <input
              type="text"
              v-model="form.handicap_index"
              class="form-control"
              readonly
            />
          </div>
          <p class="mt-2 text-subscript">
            {{ $t("text.membership.hc_base_9_hole") }}
          </p>
        </div>
      </div>

      <template #modal-footer>
        <b-button
          type="button"
          class="btn btn-outline-secondary btn-md"
          :aria-label="$t('button.common.close')"
          @click="$refs['modal-handicap-reg'].hide()"
        >
          {{ $t("button.common.close") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import locations from "@/components/Location";
import AccountTab from "@/components/membership/AccountTab";
import ax from "@/api/membership";
import VueRecaptcha from "vue-recaptcha";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyProfile",
  data() {
    return {
      profile: {},
      form: {
        id: "",

        passwordchk: "",
        curpassword: "",
        newpassword: "",
        newpassword_re: "",
        email: "",
        emailchk: "0",
        emailoverlap: "0",
        newemail: "",
        nickname: "",
        nicknameoverlap: "0",
        firstname: "",
        lastname: "",
        timezone: "0",
        daylighttime: "0",
        gender: "M",
        hand: "R",
        teeheight: "0",
        fieldunit: "",
        greenunit: "",
        speedunit: "",
        emailagree: "0",
        filename: null,
        _filename: null,
        countryno: "0",
        statesno: "",
      },
      check_form: {
        nick_chk: "n",
        email_chk: "n",
        daylighttime: false,
        emailagree: false,
        passwordchk:
          sessionStorage.getItem("is_initialize") == "1" ? true : false,
        emailchk: false,
      },
      formValidation: {
        password:
          // "비밀번호는 영문, 숫자를 포함하여 최소 8자, 최대 30자 이내로 입력해주세요.",
          this.$i18n.t("text.main.sign_up2"),
      },
      teeheight_arr: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
      timezone_arr: [
        -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8,
        9, 10, 11,
      ],
      handiscore: [],
      handiscore_limit: [],
      handiscorecnt: 0,
      timezone_list: [],
      country_list: [],
      states_list: [],
      pointList: [],
      pointListCnt: 0,
      pointSum: 0,
      view_hide: null,
      RECAPTCHA_SITEKEY: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      VUE_APP_S3_BUCKET_URL: process.env.VUE_APP_S3_BUCKET_URL,
      targetFileName: null,
      is_initialize: sessionStorage.getItem("is_initialize"),
    };
  },
  components: {
    AccountTab,
    locations,
    VueRecaptcha,
  },
  methods: {
    get_memberinfo() {
      ax.get_memberinfo((flag, data, code) => {
        if (flag) {
          // console.log(data.memberinfo[0]);
          this.form = data.memberinfo;
          this.form.handicap_index = this.hc_au(data.memberinfo.handicap_index);

          this.profile = data.memberinfo;
          this.handiscore = data.handiscore;
          this.handiscore_limit = data.handiscore_limit;
          this.handiscorecnt = data.handiscorecnt;
          this.check_form.daylighttime = this.form.daylighttime === "1";
          this.check_form.emailagree = this.form.emailagree === "1";
          this.form.nicknameoverlap = "1";
          this.form.emailoverlap = "1";
          this.check_form.nick_chk = "y";
          this.check_form.email_chk = "n";
          this.form.newpassword = "";
          this.form.newemail = "";
          this.form._filename = this.form.member_photo_url;
        } else {
          if (code == "300") {
            this.$notify({
              group: "alert",
              type: "warn",
              text: data,
              duration: 5000,
              speed: 1000,
            });
          } else alert(data);
          if (code === 401) {
            this.$store.dispatch("logout");
            this.$router.push("/");
          }
          // alert(data);
        }
      });
    },
    getPointList() {
      ax.getPointList((flag, data, code) => {
        if (flag) {
          this.pointList = data.pointlist;
          this.pointListCnt = data.pointlistcnt;
          this.pointSum = data.pointsum;
          this.$refs["pointListModal"].show({ "z-index": 9999 });
        } else {
          this.pointList = [];
          this.pointListCnt = 0;
          this.pointSum = 0;
          this.$refs["pointListModal"].show({ "z-index": 9999 });
          if (code == 300) {
            this.$notify({
              group: "alert",
              type: "warn",
              text: data,
              duration: 5000,
              speed: 1000,
            });
          } else alert(data);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.form.daylighttime =
        this.check_form.daylighttime === true ? "1" : "0";
      this.form.emailagree = this.check_form.emailagree === true ? "1" : "0";
      this.form.passwordchk = this.check_form.passwordchk === true ? "1" : "0";
      this.form.emailchk = this.check_form.emailchk === true ? "1" : "0";

      if (this.form.passwordchk == "1") {
        if (this.form.curpassword === "") {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.membership.current_password"),
            })
          );

          return false;
        }

        if (this.form.newpassword === "") {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.membership.new_password"),
            })
          );
          return false;
        }

        if (this.form.newpassword_re === "") {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.membership.new_password_confirm"),
            })
          );
          return false;
        }

        if (this.form.newpassword !== this.form.newpassword_re) {
          alert(this.$i18n.t("alert.common.password_not_match"));
          return false;
        }
      }

      if (this.form.nickname === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.common.nickname"),
          })
        );
        return false;
      }
      // if (this.check_form.nick_chk === "n") {
      //   alert("닉네임을 다시 한번 확인해주세요.");
      //   this.form.nicknameoverlap = "0";
      //   return false;
      // }
      // this.form.nicknameoverlap = "1";

      // if (this.form.lastname === "") {
      //   alert(
      //     this.$i18n.t("alert.common.field_enter", {
      //       field: this.$i18n.t("title.membership.last_name"),
      //     })
      //   );
      //   return false;
      // }

      // if (this.form.firstname === "") {
      //   alert(
      //     this.$i18n.t("alert.common.field_enter", {
      //       field: this.$i18n.t("title.membership.first_name"),
      //     })
      //   );
      //   return false;
      // }

      if (this.form.email === "") {
        alert(
          this.$i18n.t("alert.common.field_enter", {
            field: this.$i18n.t("title.membership.email"),
          })
        );
        return false;
      }
      if (this.form.emailchk == "1") {
        if (this.form.newemail === "") {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.membership.new_email"),
            })
          );
          return false;
        }
        // if (this.check_form.email_chk === "n") {
        //   alert("새 이메일을 다시 확인하세요.");
        //   this.form.emailoverlap = "0";
        //   return false;
        // }
        // this.form.emailoverlap = "1";
      }

      // if (this.form.recaptcha_yn === "n") {
      //   alert(this.$i18n.t("alert.common.recaptcha"));
      // }

      ax.member_edit(this.form, (flag, code, msg) => {
        if (flag) {
          if (code == "200") {
            this.$store.dispatch("login", {
              token: sessionStorage.getItem("token"),
              user: sessionStorage.getItem("user"),
              pk: sessionStorage.getItem("pk"),
              is_initialize: 0,
            });
            alert(msg);
            this.get_memberinfo();
          }
        } else {
          if (code == "300") {
            alert(msg);
            // location.reload();
            return false;
          } else {
            alert(msg);
            return false;
          }
        }
      });
    },
    availability_reset(type) {
      if (type === 2) {
        this.check_form.nick_chk = "n";
      } else if (type === 3) {
        this.check_form.email_chk = "n";
      }
    },
    // verify(response) {
    //   this.form.recaptcha_token = response;
    //   this.form.recaptcha_yn = "y";
    // },
    change_country() {
      this.form.statesno = "";
      this.get_stateslist();
    },
    change_states() {
      this.get_timezonelist();
    },
    get_timezonelist() {
      ax.get_timezonelist((flag, data) => {
        if (flag) {
          this.timezone_list = data.timezonelist;
        } else {
          alert(data);
        }
      });
    },
    get_stateslist() {
      ax.get_stateslist(this.form.countryno, (flag, data) => {
        if (flag) {
          this.states_list = data.stateslist;
          console.log("states_list", this.states_list);
        } else {
          alert(data);
        }
      });
    },
    get_countrylist() {
      ax.get_countrylist((flag, data) => {
        if (flag) {
          this.country_list = data.countrylist;
        } else {
          alert(data);
        }
      });
    },
    availability_check2(type, name) {
      if (name === "") {
        if (type === 1) {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.common.id"),
            })
          );
        } else if (type === 2) {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.common.nickname"),
            })
          );
        } else if (type === 3) {
          alert(
            this.$i18n.t("alert.common.field_enter", {
              field: this.$i18n.t("title.common.email"),
            })
          );
        }
        return false;
      }

      ax.availability_check2(type, name, (flag, msg, code) => {
        if (flag) {
          if (code === 200) {
            alert(msg);
            if (type === 2) {
              this.check_form.nick_chk = "y";
            } else if (type === 3) {
              this.check_form.email_chk = "y";
            }
          }
        } else {
          // alert(code)
          if (code == "300") {
            if (type === 2) {
              alert(msg);
            } else if (type === 3) {
              alert(msg);
            }
            // alert('Duplicate data.')
          } else {
            alert(msg);
          }
          return false;
        }
      });
    },
    fileSelect() {
      this.form.filename = this.$refs.file.files[0];
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    handleFileChange(e) {
      this.targetFileName = e.target.files[0].name;
      this.form.filename = this.$refs.file.files[0];
    },
  },
  watch: {
    "form.countryno"() {
      this.get_stateslist();
      console.log("변경!", this.form.countryno);
    },
  },
  // watch: {
  //   "form.newpassword": {
  //     handler: function (val) {
  //       const idReg2 = /(?=.*\d)(?=.*[a-zA-ZS]).{8,30}/; // 문자, 숫자 1개이상 포함, 8자리 이상
  //       if (!idReg2.test(val)) {
  //         this.formValidation.password =
  //           "Please enter a password of at least 8 characters and a maximum of 30 characters with including letters and numbers.";
  //       } else {
  //         this.formValidation.password = "this password is available.";
  //       }
  //     },
  //   },
  // },
  created() {
    this.get_memberinfo();
    this.get_countrylist();
    this.get_timezonelist();
  },
  mixins: [myMixin],
};
</script>
<style scoped>
.active {
  color: #35383b;
}
</style>
